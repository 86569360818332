.link {
  text-decoration: none;
}

.ps__rail-y {
  z-index: 1;
}

nav .ps .ps__rail-y,
nav .ps .ps__rail-y:hover {
  background-color: transparent !important;
}
