.case-designer-container {
  font-size: 12px;
  height: 100%;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;

  > .message {
    width: 100%;
    height: 100%;
    text-align: center;
    display: table;

    font-size: 16px;
    color: #111;

    .note {
      vertical-align: middle;
      text-align: center;
      display: table-cell;
    }

    .error {
      .details {
        max-width: 50px;
      }

      pre {
        border: solid 1px #ccc;
        background: #eee;
        padding: 10px;
      }
    }
  }
  &:not(.with-error) .error,
  &.with-error .intro,
  &.with-diagram .intro {
    display: none;
  }

  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .canvas,
  &.with-error .canvas {
    visibility: hidden;
  }

  &.with-diagram .canvas {
    visibility: visible;
  }
}

.case-designer-container .buttons {
  position: fixed;
  bottom: 20px;
  left: 20px;

  padding: 0;
  padding-left: 266px;
  margin: 0;
  list-style: none;

  > li {
    display: inline-block;
    margin-right: 10px;

    > a {
      background: #ddd;
      border: solid 1px #666;
      display: inline-block;
      padding: 5px;
    }
  }

  a {
    opacity: 0.3;
  }

  a.active {
    opacity: 1;
  }
}

#case-designer-properties {
  position: absolute;
  top: 60px;
  bottom: 0px;
  right: 0px;
  width: 260px;
  z-index: 10;
  border-left: 1px solid #ccc;
  overflow: auto;
  background-color: rgb(248, 248, 248);
  &:empty {
    display: none;
  }
  > .djs-properties-panel {
    padding-bottom: 70px;
    min-height: 100%;
  }
}
