#form-designer .fjs-form-field-text H1,
#form-designer .fjs-form-field-text H2,
#form-designer .fjs-form-field-text H3,
#form-designer .fjs-form-field-text H4 {
  font-weight: normal;
}

.form-designer .buttons {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
    margin-right: 10px;

    > a {
      background: #ddd;
      border: solid 1px #666;
      display: inline-block;
      padding: 5px;
    }
  }

  a {
    opacity: 0.3;
  }

  a.active {
    opacity: 1;
  }
}
