.multicol-combobox.wj-control {
  border-radius: 8px;
}

.multicol-combobox.wj-control:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.multicol-combobox.wj-control:focus {
  border-width: 2px;
}

.multicol-combobox.wj-control input.wj-form-control {
  padding: 16.5px 14px;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  height: 21px;
  line-height: 23px;
  box-sizing: content-box;
  min-height: unset;
}

.multicol-combobox-dropdown > div.wj-listbox-item {
  padding: 0px;
  display: none;
}
