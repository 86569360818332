.Profile-container {
  margin-top: 0px !important;
}

.profile-item {
  margin: auto;
}

.profile-pannel {
  display: flex;
  align-items: flex-start;
  padding: 20px;
}

.item-notfound {
  background-color: #999696;
  opacity: 0.5;
}

.profile-proxy {
  /* background-color: white; */
  display: flex;
  padding: 20px;
}

.profile-ProxyDelegation {
  /* background-color: white; */
  display: flex;
  padding: 20px;
}

#AdminConsoleContainer {
  display: contents !important;
}
