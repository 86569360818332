/* * {
  margin: 0;
  padding: 0;
} */

.page {
  position: relative;
}

.container {
  position: inherit;
  overflow: hidden;
  height: 85.8vh;
  width: 100%;
}

.pdfToolbar {
  float: right;
}

.barContainer {
  width: 100%;
  display: grid;
  grid-template-rows: 0 minmax(0, 1fr) 0;
  grid-template-columns: 0em 1fr 0em;
  grid-template-areas:
    "top top top"
    "lpn mid rpn"
    "bot bot bot";
}

.bar {
  font-family: sans-serif;
  align-content: center;
  text-align: center;
  grid-area: mid;
  height: 100%;
  user-select: none;
}

.number-circle {
  width: 25px;
  height: 25px;
  background: #14a6a6;
  color: white;
  font-weight: 600;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.number-circle-mobile {
  width: 25px;
  height: 25px;
  background: #14a6a6;
  color: white;
  font-weight: 600;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(30%);
}

.RSPBstep {
  bottom: -35px;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
}

.number-circle--wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}
.number-circle--wrapper-mobile {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  transform: translateY(50%);
}

.stageName {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stageName-mobile {
  display: block;
  word-wrap: break-word;
  font-size: x-small;
  min-height: 45px;
  transform: translateY(50%);
}
.bar::-webkit-scrollbar {
  height: 3px;
}

.bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.bar::-webkit-scrollbar-thumb {
  background: #888;
}

.bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
