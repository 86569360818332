.paper {
  border-radius: 16px;
  margin-top: 0px !important;
}

.mail .mail-item {
  padding: 10px 15px 10px 13px;
  width: 100%;
  line-height: 20px;
}

.mail .mail-item .mail-user {
  font-size: 12px;
  font-weight: 600;
  width: 150px;
  /* color: #434a54; */
}

.mail .mail-item .mail-text {
  font-size: 12px;
  /* color: #656d78; */
}

.mail .mail-item .mail-date {
  text-align: right;
  font-size: 12px;
}

.mail .mail-item .mail-checkbox {
  padding-left: 5px;
}

.mail .mail-stage {
  text-align: right;
}

.ActionButton {
  border-radius: 5px;
  margin: 10px !important;
  text-align: center;
  /* color: white !important; */
}

.RefreshButton {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.title {
  text-align: center;
}

@media (max-width: 375px) {
  .pagePagnation {
    padding: 0 !important;
  }

  .BreadCrumb {
    display: none;
  }
}

@media (max-width: 600px) {
  .MuiTablePagination-toolbar {
    float: right;
    padding-right: 5px;
  }

  .title {
    text-align: center;
    align-items: "center";
    padding-left: "100px";
  }
}

a {
  text-decoration: none;
}
