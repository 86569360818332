.react-flow__container {
  position: absolute;
  top: 0;
  left: 0;
}
.react-flow__nodes {
  pointer-events: none;
  transform-origin: 0 0;
}
.react-flow__node {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
  cursor: -webkit-grab;
  cursor: grab;
}
.react-flow__node_milestones {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 100%;
}
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  flex-wrap: wrap;
  font-size: 12px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
}
.react-flow__node .icon-right {
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: center center;
  z-index: 1;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  padding: 2px;
  display: flex;
  flex-direction: row;
}
.react-flow__node .icon-left {
  position: absolute;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  z-index: 1;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  padding: 2px;
  display: flex;
  flex-direction: row;
}
.react-flow .react-flow__edges {
  pointer-events: none;
  overflow: visible;
}
.react-flow__edge-path,
.react-flow__connection-path {
  stroke-width: 1;
  fill: none;
}
.react-flow__edge {
  pointer-events: visibleStroke;
  cursor: pointer;
}
.react-flow__edge.selected,
.react-flow__edge:focus,
.react-flow__edge:focus-visible {
  outline: none;
}
.react-flow__edge-textbg {
  fill: white;
}
.react-flow__edge .react-flow__edge-text {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.react-flow__edge-text {
  font-size: 10px;
}
.react-flow__node.selectable:focus,
.react-flow__node.selectable:focus-visible {
  outline: none;
}
.react-flow__handle {
  position: absolute;
  pointer-events: none;
  min-width: 5px;
  min-height: 5px;
  width: 6px;
  height: 6px;
  background: #1a192b;
  border: 1px solid white;
  border-radius: 100%;
  visibility: hidden;
}
.react-flow__handle-bottom {
  top: auto;
  left: 50%;
  bottom: -4px;
  transform: translate(-50%, 0);
}
.react-flow__handle-top {
  left: 50%;
  top: -4px;
  transform: translate(-50%, 0);
}
.react-flow__handle-left {
  top: 30px;
  left: -4px;
  transform: translate(0, -50%);
}
.react-flow__handle-right {
  right: -4px;
  top: 30px;
  transform: translate(0, -50%);
}

.react-flow__attribution {
  font-size: 0px;
}
.animation_control {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.loop_times {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(50%, 0%);
  z-index: 1;
  text-align: center;
}
.react-flow__node-circle {
  color: #fff;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  height: 100%;
  width: 125px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
}
