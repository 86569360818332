.wj-flexchart {
  width: 50%;
}

.wj-state-selected {
  stroke-width: 5px;
  stroke-dasharray: 5;
}

/* .wj-slice {
  border: 1;
} */

.wj-data-label {
  fill: rgba(255, 255, 255, 0.7);
}

.wj-label {
  fill: rgb(131, 131, 131);
}

.row-1 {
  border: none !important;
  height: 400px;
}

.row-2 {
  border: none !important;
  height: 800px;
}
.row-3 {
  border: none !important;
  height: 1200px;
}
.row-4 {
  border: none !important;
  height: 1600px;
}
.row-5 {
  border: none !important;
  height: 2000px;
}
.row-6 {
  border: none !important;
  height: 2400px;
}

@media (1200px < width) {
  [class*="row-"] {
    width: 99%;
  }
}
@media (width < 1200px) {
  [class*="row-"] {
    width: 90%;
  }
}
